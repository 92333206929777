.App {
    margin: 0.75em;
}
h2 {
  margin: 0.5rem 1rem 0.5rem 0;
}
.header {
  display: flex;
  align-items: center;
}
ul.headlines {
    list-style: none;
    padding: 0;
}
ul.headlines li {
    padding: 0.1rem;
}
html {
  color: white;
  color: lightsalmon;
  background-color: black;
}
.truncate {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  word-wrap: break-word;
  /* line-height: 1.5rem;
  height: 1.5rem; */
}
a {
  color: lightblue;    
}
a:visited {
  color: lightslategray;
}
.continuation {
  color: lightslategray;
}
.hide {
  display: none;
}
